@import "~bootstrap/scss/bootstrap";
@import "../definitions";

.games {
  background-color: $primaryColor;
  .games__title {
    color: $white;
    text-align: center;
    font-size: 3.5rem;
  }
  .games__game {
    @extend .col-lg-4;
    @extend .separator__vertical--xl;
    text-align: center;
    img {
      width: 100%;
    }
  }
}