@import "~bootstrap/scss/bootstrap";
@import "definitions";



.red {
  background-color: red;
}

body {
  background-color: $background;
  margin: 0;
  color: $text;
}

.eniltex {
  .content {
    min-height: $page-height;
  }
}

@import "layout";