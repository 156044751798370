@import "~bootstrap/scss/bootstrap";
@import "../definitions";
.footer {
  padding: 1.5rem;
  color: $white;
  background-color: $dark;
  position: revert;
  bottom: 0;
  width: 100%;
  .footer__brand {
    @extend .col-lg-5;
    @extend .col-md-6;
    font-size: 1.25rem;
    img {
      height: 2.5rem;
    }
    .footer__brand__description {
      margin-top: 1rem;
      font-size: 0.8rem;
      text-align: justify;
      width: 15rem;
    }
  }
  .footer__menu {
    @extend .separator__vertical--xl;
    @extend .col-lg-2;
    @extend .col-md-3;
    .footer__menu__title {
      font-weight: 700;
    }
  }
  .footer__rights__claim {
    text-align: center;
  }

}