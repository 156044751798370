@import "~bootstrap/scss/bootstrap";
@import "../definitions";

.text__image__block {
  @extend .container;
  .text__image__block__half {
    @extend .col-lg-6;
    @extend .separator__vertical--xl;
    color: $text;
    font-size: 1.3rem;
    font-weight: 100;
    text-align: justify;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
}