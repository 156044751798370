@import "../definitions";
.welcome {
  height: $page-height;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../../assets/theme-picture.jpg");
  background-size: cover;
  .welcome-content {
    color: $white;
    font-weight: 600;
    font-size: 2.53rem;
    text-align: center;
    margin: auto;
    width: 50rem;
  }
}