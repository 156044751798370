.menu {
  @extend .navbar;
  @extend .navbar-expand-lg;
  @extend .navbar-light;
  @extend .bg-light;
  color: $primaryColor;
  background-color: $white;
  .menu-content {
    @extend .container-fluid;
    .menu-brand {
      @extend .navbar-brand;
      button {
        @extend .btn;
        @extend .btn-primary
      }
      img {
        height: 2.5rem;
      }
    }
    .menu-toggler {
      @extend .navbar-toggler
    }
    .menu-toggler-icon {
      @extend .navbar-toggler-icon
    }
  }
}