@import "font";

$background: #fff;
$primaryColor: #0d46a0;
$dark: #002071;
$white: #ffffff;
$a: #65499c;
$text: #1b1b1b;
$textOnPrimary: #ffffff;
$linkColor: $dark;
$page-height: calc(100vh - 66px);
$verticalSpace: 3rem;

@media only screen and (max-width: 992px) {
  .separator__vertical--xl {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}