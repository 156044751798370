@import "~bootstrap/scss/bootstrap";
@import "../definitions";

.text__block {
  @extend .container;
  color: $text;
  font-size: 1.3rem;
  font-weight: 100;
  text-align: justify;
  display: flex;
  justify-content: center;
  align-items: center;
}